.product-price-filter
	.ui-slider
		height: r(6px)
		border-radius: r(12px)
		border: 0
		+bg(gray-50)
		&-range
			+bg(secondary-600)
		&-handle
			+circle(20px)
			+bg(white)
			border: 2px solid color(secondary-600)

.product-filter-wrap
	+mq-max(lg)
		margin-top: 0 !important
		position: fixed
		top: 0
		right: -100%
		height: 100%
		z-index: 100
		+bg(primary-50)
		width: calc(100% - 5rem)
		padding: r(20px)
		+trans-all
		overflow: auto
		&.show
			right: 0

.product-side-list
	+content-margins
		padding-top: r(16px)
		margin-top: r(16px)
		border-top: 1px solid color(gray-100)
