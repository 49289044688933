.product-sort
	+mq-min(sm)
		justify-content: flex-end

.product-sort-item
	display: flex
	gap: r(20px)
	align-items: center
	select
		+background-svg($sl-bg-gray)
		+text(gray-300)
