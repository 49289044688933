.product-item
	+trans-all
	border: 1px solid transparent
	position: relative
	&-image
		figure
			border: 1px solid #B9B9B9
			img
				object-fit: contain
	&-content
		padding: r(12px) r(12px) r(24px)
	&-labels
		position: absolute
		top: 0
		right: 0

	.add-to-cart
		display: flex
		+circle(54px)
		+flex-center
		+bg(gray-50)
		+fz(24px)
		+text(primary-500)
		+trans-all
		+on-hover
			+bg(primary-500)
			+text(white)
	&-horizontal
		display: flex
		gap: r(16px)
		.product-item-image
			+flex-width(r(68px))
		.product-item-content
			flex: 1
			padding: 0
	&-vertical
		+on-hover
			border-color: color(secondary-600)

.product-label
	min-width: r(50px)
	min-height: r(66px)
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	text-align: center
	clip-path: polygon(100% 0%, 100% 100%, 50% 88%, 0 100%, 0 0)
	&-discount
		+bg(secondary-600)
		color: #F8DA6F
