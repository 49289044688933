.blog-item
	+bg(white)
	@extend .img-zoom-out
	&-image
		figure
			+res-ratio(640,360)
			overflow: hidden
	&-brief
		+line(3)
	.blog-item-title
		+trans-all
		+parent-state(":hover")
			+text(primary-400)
