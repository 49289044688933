.form-group
	+all-text-inputs
		height: r(48px)
		width: 100%
		+bg(white)
		border: 1px solid color(gray-100)
		padding: 0 r(20px)
		+fz(16px)
		border-radius: r(4px)
		+placeholder
			+fz(16px)
			+text(gray-500)
			opacity: 1
	select
		height: r(48px)
		+fz(16px)
	textarea
		height: r(160px)
		padding: r(12px) r(20px)
	&.qradio,&.qcheckboxes
		ul
			display: flex
			gap: r(40px)
			flex-wrap: wrap
			+mq-max(md)
				gap: r(20px)
			li
				label
					display: flex
					@extend .body-3
					+text(gray-200)
					gap: r(12px)
					font-weight: 500
					&::before
						+icon("\f111")
				input
					+hide
					&:checked
						& ~ label
							+text(gray-500)
							&::before
								+icon("\f058")
								+text(primary-500)
select
	width: 100%
	+bg(white)
	padding-right: r(44px)
	appearance: none
	padding-left: r(20px)
	border: 0
	border-radius: r(4px)
	background-repeat: no-repeat
	background-position: calc(100% - 1.04166667rem) 50%

.frm-btnwrap
	margin-top: r(20px)
	.label
		display: none

.frm-btn
	position: relative
	display: inline-block
	// &:before
	// 	+center(Y)
	// 	right: r(32px)
	// 	content: '\f178'
	// 	font-family: "Font Awesome 6 Pro"
	// 	+text(primary-500)
	// 	pointer-events: none
	// 	font-weight: 300
	// 	+fz(20px)
	input[type="submit"]
		+trans-all
		+bg(secondary-600)
		+text(white)
		border: 0
		height: r(48px)
		cursor: pointer
		+fz(16px)
		border-radius: r(4px)
		min-width: r(68px)
		text-transform: uppercase
		+trans-all
		+on-hover
			+bg(secondary-700)
