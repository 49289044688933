.breadcrumb-banner-section
	position: relative
	.page-title-breadcrumb-wrapper
		position: absolute
		bottom: 0
		left: 0
		padding-bottom: r(20px)
		padding-top: r(96px)
		width: 100%
		background: linear-gradient(180deg, rgba(0, 143, 211, 0.00) 0%, rgba(0, 143, 211, 0.69) 100%)
		+text(white)
	.breadcrumb
		+text(white)
		&-wrapper
			background: transparent
			.container
				padding: 0

.news-detail-page,.recruitment-detail-page,.searchresults
	.breadcrumb-banner-section
		.banner-section
			display: none
		.page-title-breadcrumb-wrapper
			position: static
			padding: 0
			border-radius: 0
			background: transparent
			width: 100%
			+bg(white)
			.page-title
				display: none
			.breadcrumb
				+text(gray-950)
				.container
					padding: 0 r(16px)
				&-wrapper
					padding: r(20px) 0

.booking-page
	.breadcrumb-banner-section
		.banner-section
			display: none
		.page-title-breadcrumb-wrapper
			position: static
			border-radius: 0
			background: transparent
			width: 100%

.banner-item
	&-img
		+mq-max(md)
			figure
				+res-ratio(3,4)
				padding-top: 62.5%
