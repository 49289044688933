.promo-banner
	.row
		+gap-override(12px)
	.item
		.img
			figure
				+res-ratio(869,286)
		&-single
			figure
				+res-ratio(1316,438)
		&-small
			.img
				figure
					+res-ratio(429,286)
	&-slider
		.swiper-pagination
			display: flex
			gap: r(12px)
			justify-content: center
			bottom: r(14px)
			&-bullet
				width: r(16px)
				height: r(16px)
				opacity: 1
				background: transparent
				border: 1px solid color(white)
				margin: 0 !important
				position: relative
				&::before
					content: ''
					position: absolute
					top: 50%
					left: 50%
					transform: translate(-50%, -50%)
					width: r(24px)
					height: r(24px)
					border-radius: 50%
					transition: all .3s ease
					border: 1px solid color(secondary-600)
					opacity: 0
				&-active
					+bg(secondary-600)
					border: 0
					&::before
						opacity: 1
