.home-product-list
	.section-heading
		.section-title
			display: flex
			align-items: center
			+text(white)
			.icon
				display: flex
				+flex-center
				+box(80px,70px)
				+flex-width(r(80px))
				border-radius: r(12px) 0px 0px r(12px)
				+bg(primary-500)
				img
					width: 80%
					filter: brightness(0) invert(1)
			.title
				@extend .heading-4
				font-weight: 700
				padding: r(10px) r(20px)
				border-radius: 0px r(12px) r(12px) 0px
				white-space: nowrap
				+bg(primary-500)
			&.is-red
				.icon
					+bg(secondary-600)
				.title
					+bg(secondary-600)
