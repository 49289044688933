.breadcrumb-wrapper
	+remove(lg, 'max')
	padding: r(20px) 0
	+bg(white)
	.breadcrumb
		display: flex
		align-items: center
		gap: r(10px)
		color: inherit
		li
			position: relative
			display: flex
			align-items: center
			gap: r(10px)
			@extend .body-3
			a
				display: flex
				align-items: center
			& + li
				&:before
					content: '\f054'
					font-family: 'Font Awesome 6 Pro'
					font-weight: 300
			+first(1)
				a
					font-size: 0
					&:before
						+fz-lh(16px,24px)
						content: '\f015'
						font-family: 'Font Awesome 6 Pro'
						font-weight: 300
