.home-featured-product
	.section-heading
		+mq-max(lg)
			justify-content: center !important
	.tab-nav
		max-width: 100%
		ul
			display: flex
			gap: r(20px)
			overflow: auto
			max-width: 100%
			li
				& + li
					padding-left: r(20px)
					border-left: 1px solid rgba(color(white), 0.5)
				a
					+text(white)
					@extend .heading-5
					font-weight: 700
					display: inline-block
					padding: r(10px) 0
					border-bottom: 1px solid transparent
					transition: all 0.3s ease
					white-space: nowrap
				&.active
					a
						border-bottom: 1px solid color(white)

.featured-product-list
	.row
		+gap-override(12px)

.deal-countdown
	display: flex
	gap: r(4px)
	align-items: center
	+text(white)
	@extend .heading-5
	span
		+box(40px)
		border-radius: r(4px)
		+bg(white)
		display: flex
		+flex-center
		+text(secondary-600)
		font-weight: 700
