.off-container
	+mq-min(xl)
		margin: 0 r(-32px)

.dealer-result
	height: r(646px)
	overflow-y: auto
	+scrollbars(2px, color(primary-400), color(gray-50))
	padding-right: r(8px)

.dealer-result-list
	border: 1px solid color(gray-100)
	+bg(white)
	border-radius: r(8px)
	+content-margins
		border-top: 1px solid color(gray-100)
	.dealer-item
		padding: r(20px) r(24px)
		cursor: pointer
		ul
			+layout-col(8px)
			li
				display: flex
				gap: r(24px)
				em
					+flex-width(r(16px))
					position: relative
					top: r(4px)
					+text(primary-400)
		.title
			+trans-all
		&.active
			+bg(primary-50)
			.title
				+text(primary-600)

.dealer-map
	iframe
		width: 100%
		height: 100%
		+mq-max(lg)
			height: r(400px)

.contact-wrap
	.btn
		min-width: r(192px)
