.feedback-item
	&-title
		display: flex
		gap: r(12px)
		align-items: center
	&-content
		+bg(white)
		padding: r(60px) r(32px) r(24px)
		+radius(8px)
	&-img
		margin-bottom: r(-45px)
		figure
			+circle(90px)
			@extend .object-cover
			overflow: hidden
			margin-left: r(32px)
