.global-features
	.global-feature
		display: flex
		align-items: center
		gap: r(12px)
		+mq-max(sm)
			flex-direction: column
			text-align: center
		+mq-min(lg)
			padding: 0 r(12px)
		&-icon
			+flex-width(r(50px))
			height: r(50px)
			display: flex
			+flex-center
		&-title
			@extend .body-3
			font-weight: 700

.store-finder-form,.subscription-form
	.form-group
		select,input
			background-color: transparent
			border: 1px solid color(white)
			+text(white)
			+placeholder
				+text(white)
				opacity: 1
		select
			+background-svg($sl-bg-white)
	.frm-btnwrap
		margin-top: r(12px)

.store-finder-form
	.frm-btnwrap
		width: calc(50% - .625rem)
		.frm-btn
			width: 100%
			input
				width: 100%
				+bg(white)
				+text(secondary-600)

.subscription-form
	+mq-min(md)
		padding-left: r(32px)
		border-left: 1px solid rgba(#fff , 0.3)
	.frm-btnwrap
		.frm-btn
			input
				min-width: r(68px)

.footer-title
	padding: r(8px) 0
	@extend .body-1
	font-weight: 700
	text-transform: uppercase

.footer-links
	a
		@extend .body-3
		&:hover
			text-decoration: underline
	&.is-1-col
		> ul
			+layout-col(16px)
	&.is-2-col
		ul
			display: flex
			flex-wrap: wrap
			gap: r(16px)
			> li
				flex: 0 0 40%
				max-width: 40%

.footer-bottom
	border-top: 1px solid #ddd

.copyright
	@extend .body-4
	color: #999
	text-align: center
