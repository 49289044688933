.home-section-2
	.item
		display: flex
		align-items: center
		gap: r(12px)
		+mq-max(sm)
			flex-direction: column
			text-align: center
		+mq-min(lg)
			width: r(272px)
			margin: 0 auto
			padding: 0 r(12px)
		.icon
			+flex-width(r(70px))
			height: r(70px)
			display: flex
			border-radius: 50%
			+bg(white)
			+flex-center
			img
				width: 60%
		.title
			@extend .body-3
			font-weight: 700
			+text(white)
