.progress-bar
	+bg(secondary-100)
	height: r(20px)
	border-radius: r(20px)
	overflow: hidden
	position: relative
	&-inner
		border-radius: r(20px)
		position: absolute
		top: 0
		left: 0
		height: 100%
