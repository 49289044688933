.product-sidenav
	.toggle
		+fz(20px)
		cursor: pointer
	li
		display: flex
		justify-content: space-between
		align-items: center
		flex-wrap: wrap
		&.active
			> a
				+text(secondary-600)
			> .toggle
				+text(secondary-600)
				&::before
					content: "\f068"
	.menu-lv1
		> li
			padding: r(16px) 0
			border-top: 1px solid color(gray-100)
	.menu-lv2,.menu-lv3
		+flex-width(100%)
		padding-left: r(16px)
	.menu-lv2
		padding-top: r(20px)
		> li
			& + li
				margin-top: r(16px)
	.menu-lv3
		padding-top: r(16px)
		> li
			a
				padding: r(8px) 0
				display: block
