.detail-share
	ul
		display: flex
		gap: r(12px)
		li
			a
				+circle(46px)
				display: flex
				+flex-center
				@extend .body-1
				+text(primary-500)
				+trans-all
				+bg(white)
				+mq-max(2xl)
					+bg(primary-500)
					+text(white)
	+mq-max(2xl)
		margin-top: r(32px)

.news-detail
	.detail-share
		+mq-min(2xl)
			position: absolute
			top: 0
			right: 100%
			margin-right: r(32px)
			height: 100%
			ul
				flex-direction: column
				position: sticky
				top: calc(var(--header-height) + 20px)

.news-other-list
	+content-margins
		margin-top: r(16px)
		padding-top: r(16px)
		border-top: 1px solid #EFEFEF
