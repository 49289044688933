$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)

@each $color, $value in $button
	$hover-color: map-get($button-hover, $color)

	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, #333, #fff)
		+on-hover
			background-color: $hover-color
			color: contrast-color($hover-color, #333, #fff)
			border-color: $hover-color

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, #333, #fff)

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(12px) r(24px)
	display: inline-flex
	+trans-all
	+font(400,18px,24px)
	+flex-center
	gap: r(12px)
	border-radius: r(4px)
	em
		+fz(18px)
	&-pill
		border-radius: r(56px)

.swiper-btn
	display: inline-flex
	+flex-center
	+box(40px)
	+text(gray-500)
	font-size: r(32px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+on-hover
		+text(primary-500)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none
	&.no-box
		width: auto
		height: auto
		background: transparent
		+text(gray-300)
		+on-hover
			+text(primary-500)

.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(24px)
			&-prev
				right: 100%
			&-next
				left: 100%
			+mq-max(2xl)
				margin: 0 r(12px)

		&.is-no-gap
			.swiper-btn
				margin: 0
				&-prev
					right: auto
					left: r(20px)
				&-next
					left: auto
					right: r(20px)
	&.is-left
		display: flex
		gap: r(12px)
