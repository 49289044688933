.global-header
	+bg(white)
	box-shadow: 4px 4px 32px 16px rgba(0, 0, 0, 0.04)
	position: relative
	z-index: 2
	width: 100%

.top-header
	padding: r(28px) 0 r(18px)
	+mq-max(md)
		padding: r(16px) 0
		.logo-wrapper
			order: 0
		.search-wrapper
			order: 2
			display: none
		.util-wrapper
			order: 1

.bottom-header
	padding: r(16px) 0
	border-top: 1px solid color(gray-50)

.logo
	width: r(250px)
	max-width: 100%
	>a
		display: block
		+res-ratio(250, 60)
		img
			object-fit: contain

.search-box
	display: flex
	width: 100%
	border-radius: r(4px)
	input
		height: r(44px)
		border: 0
		border-left: 1px solid color(primary-400)
		background: #F1FAFE
		padding: 0 r(16px)
		flex: 1
		+fz(16px)
		+placeholder
			+fz(14px)
			+text(gray-500)
	button
		+flex-width(r(68px))
		width: r(68px)
		height: r(44px)
		+bg(primary-400)
		border: 0
		+text(white)
		+fz(20px)

.language
	display: flex
	align-items: center
	gap: r(8px)
	padding: r(4px) r(8px)
	font-weight: 700
	+text(gray-400)
	@extend .body-4
	a
		display: inline-block
		& + a
			border-left: 1px solid #DDD
			padding-left: r(8px)
		&.active
			+text(primary-400)

.account
	+mq-max(lg)
		width: 100%
	.dropdown-content
		min-width: 100%
		+mq-max(lg)
			position: static

.menu
	ul
		display: flex
		gap: r(40px)
		+mq-max(lg)
			flex-direction: column
			gap: r(16px)
		li
			a
				@extend .body-1

.header-cta-links
	@extend .body-1
	display: flex
	gap: r(12px)
	> * + *
		padding-left: r(12px)
		border-left: 1px solid color(gray-300)

.check-order-wrapper,.hotline-wrapper
	a
		display: flex
		align-items: center
		gap: r(6px)

.hotline-wrapper
	a
		gap: r(12px)
		+text(secondary-600)

.menu-btn
	height: r(44px)
	display: flex
	+flex-center
	padding: r(12px)
	+bg(primary-50)
	+text(primary-400)
	@extend .body-4
	gap: r(8px)
	em
		+fz(20px)

.menu-toggle, .search-toggle
	width: r(44px)

.search-toggle
	+mq-min(md)
		display: none

.menu-mobile
	position: fixed
	padding: r(32px) r(24px) r(40px)
	top: 0
	left: -100%
	width: 80vw
	max-width: 320px
	height: 100%
	+bg(white)
	overflow: auto
	z-index: 102
	+trans-all

	&.show
		left: 0

	.close-menu
		position: absolute
		+box(44px)
		display: flex
		+flex-center
		+bg(gray-100)
		top: 0
		right: 0
		font-size: r(24px)
		z-index: 2
		&.close-mega
			right: auto
			left: 0
	.menu-mobile-header
		margin-bottom: r(20px)
		display: flex
		align-items: center
		justify-content: space-between
	.menu-mobile-body
		+content-margins
			padding-top: r(24px)
			margin-top: r(24px)
			border-top: 1px solid color(gray-100)

.backdrop
	+fill
	position: fixed
	opacity: 0.5
	+bg(gray-500)
	z-index: 95
	display: none
