.faq-list
	.accordion-item
		& + .accordion-item
			margin-top: r(20px)
			padding-top: r(20px)
			border-top: 1px solid color(gray-100)
		.accordion-title
			cursor: pointer
			+text(gray-500)
			+parent-state(".active")
				+text(secondary-600)
				span
					&:before
						content: "\f068"
