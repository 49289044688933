.home-section-9
	background: #F1FAFE

.feedback-slider
	.swiper-pagination
		position: static
		margin-top: r(32px)
		opacity: 1
		&-bullet
			+circle(10px)
			+bg(white)
			border: 1px solid color(primary-400)
			&-active
				+bg(primary-400)
