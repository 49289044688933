.product-category-slider
	&-wrap
		+mq-min(lg)
			padding: 0 r(72px)

.product-category-item
	*
		+trans-all
	&-image
		figure
			+res-ratio(1,1)
			border-radius: r(4px)
			border: 1px solid color(gray-100)
			img
				object-fit: contain
	+on-hover
		.product-category-item-title
			+text(primary-500)
		.product-category-item-image
			figure
				border-color: color(primary-500)
