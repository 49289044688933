.sidenav
	ul
		li
			margin-top: r(20px)
			padding-top: r(20px)
			border-top: 1px solid color(gray-100)
			a
				+trans-all
				+parent-state(":hover", ".active")
					+text(secondary-600)
