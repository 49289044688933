.news-item
	padding: r(20px)
	+bg(white)
	@extend .img-zoom-out
	&-image
		figure
			overflow: hidden
			+res-ratio(372,211)
	&-brief
		+line(3)
	.news-item-title
		+trans-all
		+parent-state(":hover")
			+text(primary-400)

.news-side
	display: flex
	gap: r(20px)
	align-items: center
	&-img
		+flex-width(120 / 352 * 100%)
		figure
			+res-ratio(120,68)
	&-content
		flex: 1
	&-title
		+line(2)
