.product-detail
	.detail-share
		margin-top: 0
		a
			+bg(gray-50)
			+text(primary-500)

.rating-star
	i
		font-size: r(28px)
		&.star-on-png
			color: #FFB617
			&:before
				+icon("\f005")
				font-weight: 900
		&.star-half-png
			font-weight: 900
			position: relative
			font-family: "Font Awesome 6 Duotone"
			&:before
				color: #FFB617
				content: "\f089"
				position: absolute
			&:after
				content: "\f089\f089"
				color: #F6F6F6
		&.star-off-png
			color: #F6F6F6
			&:before
				font-weight: 900
				+icon("\f005")

.product-detail-images
	position: relative
	.trigger-zoom
		position: absolute
		bottom: r(20px)
		right: r(20px)
		+fz(32px)
		+text(primary-500)
		z-index: 2
	.img
		padding-top: 100%
		position: relative
		+bg-opa(gray-50, 0.8)
		figure
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			display: flex
			+flex-center
			padding: 0 r(40px)

.product-detail-thumbnail
	.img
		figure
			+res-ratio(1,1)
			+bg-opa(gray-50, 0.8)
			border-radius: r(4px)
			border: 1px solid transparent
			+trans-all
			img
				object-fit: contain
				width: 80%
				+center(both)
	.swiper-slide-thumb-active
		figure
			border-color: color(secondary-600)

.product-flash-sale
	.progress-bar
		flex: 1
		.flash-sale-time
			position: absolute
			+center(both)
			+text(white)
		&-inner
			+bg(secondary-600)

.product-detail-price
	position: relative
	height: r(56px)
	+mq-min(lg)
		padding-right: r(48px)
	.product-detail-labels
		position: absolute
		top: 0
		right: r(24px)
	.product-label
		min-width: r(36px)
		min-height: r(48px)
		padding: r(4px)
		strong,span
			+fz(12px)

.product-detail-brief
	table
		tr
			td
				padding: r(16px) 0
				border-top: 1px solid color(gray-100)
			&:last-child
				td
					padding-bottom: 0

.product-detail-feature
	padding: r(20px)
	border-radius: r(8px)
	border: 1px solid color(gray-100)
	@extend .body-3
	ul
		li
			display: flex
			gap: r(20px)
			align-items: center
			.icon
				+flex-width(r(40px))
			& + li
				margin-top: r(20px)
				padding-top: r(20px)
				border-top: 1px solid color(gray-100)

.product-detail-description
	table
		tr
			td
				padding: r(12px) r(20px)
				border-top: 0
				border-right: 0
				border-left: 0
				border-bottom: 1px solid color(primary-200)
				&:first-child
					background: #F1FAFE

.product-detail-description
	.expand-trigger
		cursor: pointer
		&.active
			em
				&:before
					+icon("\f062")
	.expand-content
		max-height: r(704px)
		+trans-all
		&.expanded
			max-height: var(--orginal-content-height)

.product-detail-document
	&-list
		+content-margins
			padding-top: r(16px)
			margin-top: r(16px)
			border-top: 1px solid color(gray-100)

.spin-input-wrap
	width: r(144px)
	+layout-row(16px)
	height: r(48px)
	border: 1px solid color(gray-200)
	padding: r(12px) r(16px)
	align-items: center
	justify-content: space-between
	+radius(999px)
	.btn-spin,.spin-btn
		cursor: pointer
		+fz(20px)
	input
		border: 0
		text-align: center
		+flex-width(r(40px))
		+fz(20px)
