.product-category-menu
	position: relative
	&-title
		border-radius: r(8px) r(8px) 0 0
	&-list
		+bg(white)
		padding: r(32px) r(12px)
		border-radius: 0 0 r(8px) r(8px)
		> ul
			padding-right: r(12px)
			height: r(360px)
			overflow-y: auto
			overflow-x: visible
			+scrollbars(2px, color(primary-600), color(gray-100))
			> li
				margin-bottom: r(12px)
				padding-bottom: r(12px)
				border-bottom: 1px dashed #E1E1E1
				display: flex
				align-items: center
				@extend .body-1
				gap: r(16px)
				.icon
					height: r(22px)
					+flex-width(r(28px))
					display: flex
				.toggle
					@extend .body-3
					color: #BBB
					margin-left: auto
				&.has-cate
					position: relative
					flex-wrap: wrap

	.sub-cate
		+bg(white)
		+mq-min(lg)
			position: absolute
			left: 100%
			top: 0
			height: 100%
			margin-left: r(20px)
			padding: r(60px)
			border-radius: r(8px)
			overflow: auto
			display: none
			z-index: 2
			+scrollbars(2px, color(primary-600), color(gray-100))
		+mq-max(lg)
			+flex-width(100%)
			display: none
		&.show
			display: block
		.menu-lvl-1-title
			margin-bottom: r(12px)
			+bg(primary-50)
			padding: r(4px) r(12px)
		ul
			+layout-col(8px)
			padding-left: r(12px)

.home-section-1
	.row
		+gap-override(20px)

.home-banner
	.row
		+gap-override(12px)
	.banner-item
		figure
			border-radius: r(8px)
			overflow: hidden
			+res-ratio(649, 484)
	&-right
		+mq-min(lg)
			display: flex
			flex-direction: column
			gap: r(12px)
			justify-content: space-between
		+mq-max(lg)
			+row(12px)
			> *
				+flex-width(50%)
